import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { sociallinksfunction } from "../../actions/v1/report";
import config from "../../lib/config";
import { useSelector } from "react-redux";
import unblokLogo from "../../assets/images/Header/newLogo.svg";
import twiter from "../../assets/images/Footer/twitter.svg";
import instagram from "../../assets/images/Footer/instagram.svg";
import facebook from "../../assets/images/Footer/facebook.svg";

export default function Footer() {
  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [sociallinks, setsociallinks] = useState({});
  useEffect(() => {
    sociallinksfunct();
  }, [
    Wallet_Details.UserAccountAddr,
    localStorage.walletConnectType,
    config.providercon,
  ]);

  const sociallinksfunct = async () => {
    var soci = await sociallinksfunction();
    if (soci !== undefined && soci?.data?.soci !== undefined) {
      setsociallinks(soci.data.soci);
    } else {
      setsociallinks({});
    }
  };

  const explore = () => {
    document.body.scrollTop = 0;
  };

  return (
    <>
      <footer className="footerhead scroll-to-top">
        <div className="container-fluid custom-container footer_bgcolor">
          <div className="row pb-3">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12">
              <div>
                <Link to="/" className="footer__logo">
                  <img
                    alt="Footer-Logo"
                    className="footer_top_logo"
                    src={unblokLogo}
                  />
                </Link>
              </div>
              <p className="robotoReg footer_xpln">
                Explore the limitless possibilities of our
                Data NFTs to power your investment decisions.
              </p>
              <div className="footersociallinks">
                <a href={sociallinks.Discord} target="_blank" rel="noreferrer">
                  <img src={facebook} style={{ width: "28px" }} alt="" />
                </a>
                <a
                  href={sociallinks.Instagram}
                  target="_blank"
                  alt=""
                  rel="noreferrer"
                >
                  <img src={instagram} style={{ width: "28px" }} alt="" />
                </a>
                <a
                  href={sociallinks.Twitter}
                  target="_blank"
                  alt=""
                  rel="noreferrer"
                >
                  <img src={twiter} style={{ width: "28px" }} alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12">
              <div className="row footer_blocks">
                <h2 className="robotoBold">Data Collections</h2>
                <div className="footer-columns-wrapper">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                    <div className="quicklinks">
                      <div className="scroll-to-top"></div>
                      <ul>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Shopping"
                            onClick={explore}
                          >
                            Shopping
                          </Link>
                        </li>

                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Social Media"
                            onClick={explore}
                          >
                            Social Media{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                    <div className="quicklinks">
                      <ul>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Travel"
                            onClick={explore}
                          >
                            Travel
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Browser"
                            onClick={explore}
                          >
                            Browser
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                    <div className="quicklinks">
                      <ul>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/News"
                            onClick={explore}
                          >
                            News
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Search"
                            onClick={explore}
                          >
                            Search
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                    <div className="quicklinks">
                      <ul>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Entertainment"
                            onClick={explore}
                          >
                            Entertainment
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Transportation"
                            onClick={explore}
                          >
                            Transportation
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="footer_noner">
                <h2 className="robotoBold">Data Collections</h2>
                <div className="footer_flez">
                  <div className="quicklinks">
                    <ul>
                      <li>
                        <Link to="/explore/Shopping" onClick={explore}>
                          Shopping
                        </Link>
                      </li>
                      <li>
                        <Link to="/explore/Social Media" onClick={explore}>
                          Social Media
                        </Link>{" "}
                      </li>
                      <li>
                        <Link to="/explore/News" onClick={explore}>
                          News
                        </Link>
                      </li>
                      <li>
                        <Link to="/explore/Travel" onClick={explore}>
                          Travel
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="quicklinks">
                    <ul>
                      <li>
                        <Link to="/explore/Browser" onClick={explore}>
                          Browser
                        </Link>{" "}
                      </li>
                      <li>
                        <Link to="/explore/Search" onClick={explore}>
                          Search
                        </Link>
                      </li>
                      <li>
                        <Link to="/explore/Transportation" onClick={explore}>
                          Transportation
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/explore/Entertainment" onClick={explore}>
                          Entertainment
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="copyrightWrap">
          <p className="copyrights">Copyright ©2024. All rights reserved.</p>
          <p className="copyrightsde">
            <span>
              <Link to="/privacy-policy">Privacy Policy</Link> |{" "}
            </span>
            <span>
              <Link to="/terms-of-service">Terms of Service</Link> |{" "}
            </span>
            <span>
              <Link to="/disclaimer">Disclaimer </Link>
            </span>
          </p>
        </div>
      </footer>
    </>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
