// import React, { useState, useEffect} from "react";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { useHistory, useLocation } from "react-router-dom";
// import editprofile from "../assets/images/img-4.png";
// import $ from "jquery";
// import isEmpty from "../lib/isEmpty";
// import config from "../lib/config";
// import "react-toastify/dist/ReactToastify.css";
// import { getSearchList } from "../actions/v1/user";
// import "./home.css";
// export default function SearchBar(props) {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const history = useHistory();
//   var location = useLocation();
//   const [show, setShow] = useState(false);
//   const [searchItem, setSearchItem] = useState([]);
//   const [search, setSearch] = useState(false);
//   const [keyword, setKeyword] = useState();
//   const [UsersearchItem, setUserSearchItem] = useState([]);

//   if (show == true) {
//     $("body").addClass("overflowbody");
//   } else {
//     $("body").removeClass("overflowbody");
//   }
//   useEffect(() => {
//     searchinit("a");
//   }, [location?.pathname]);

//   const searchinit = async (keywordVal) => {
//     let postData = {
//       limit: 6,
//       keyword: keywordVal,
//     };
//     var data = await getSearchList(postData);
//     if (
//       data &&
//       data.searchlist &&
//       data.searchlist.items &&
//       data.searchlist.items.list
//     ) {
//       setSearchItem(data.searchlist.items.list);
//     }
//     if (data && data.searchlist && data.searchlist.users) {
//       setUserSearchItem(data.searchlist.users);
//     }
//   };

//   const toggleSearchmenu = async (event) => {
//     var useclass = document.getElementsByClassName("searchmneu_dd");
//     if (event?.target?.value?.length == 1) {
//       for (var c = 0; c < useclass.length; c++) {
//         useclass[c].classList.remove("d-none");
//       }
//     }
//     if (event?.target?.value?.length == 0) {
//       for (var c = 0; c < useclass.length; c++) {
//         useclass[c].classList.add("d-none");
//       }
//     }
//     let keywordVal = event.target.value;
//     setKeyword(keywordVal);
//     let postData = {
//       limit: 6,
//       keyword: keywordVal,
//     };
//     var data = await getSearchList(postData);
//     if (
//       data &&
//       data.searchlist &&
//       data.searchlist.items &&
//       data.searchlist.items.list
//     ) {
//       setSearchItem(data.searchlist.items.list);
//     }
//     if (data && data.searchlist && data.searchlist.users) {
//       setUserSearchItem(data.searchlist.users);
//     }
//   };

//   let scrollTop = window.scrollY;
//   $(window).scroll(function (event) {
//     if (scrollTop > 150) {
//       alert(scrollTop);
//       $("nav").addClass("scroll");
//     } else {
//       $("nav").removeClass("scroll");
//     }
//   });
//   var scrollpos = window.scrollY;
//   var header = document.getElementById("navbar");

//   function add_class_on_scroll() {
//     header.classList.add("fade-in");
//   }

//   function remove_class_on_scroll() {
//     header.classList.remove("fade-in");
//   }

//   window.addEventListener("scroll", function (event) {
//     scrollpos = window.scrollY;

//     if (scrollpos > 10) {
//       add_class_on_scroll();
//     } else {
//       remove_class_on_scroll();
//     }
//   });

//   window.$("body").scroll(function () {
//     var scroll = window.$("body").scrollTop();

//     if (scroll >= 50) {
//       $("nav").addClass("scroll");
//     } else {
//       $("nav").removeClass("scroll");
//     }
//   });
//   return (
//     <>
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-lg-6 col-md-9 col-sm-8 col-12">
//             <div className="seathbat">
//               <div className="seathbats">
//                 <div className="searchiconsd">
//                   <div className="searchbar">
//                     <div className="searchbarsd">

//                       <input
//                         type="text"
//                         placeholder="Search Data Tokens"
//                         className={search ? "serchitems" : "serchitems"}
//                         onChange={(e) => toggleSearchmenu(e)}
//                         value={keyword}
//                         onClick={() => setSearch(true)}
//                       />
//                       <i className="fa fa-search search_galass" />
//                       {search && (
//                         <i
//                           className="fa fa-times search_closer"
//                           onClick={() => {
//                             setSearch(false);
//                             setKeyword("");
//                           }}
//                         ></i>
//                       )}
//                     </div>
//                     <div
//                       className={
//                         search
//                           ? "searchlist searchlist_height active"
//                           : "searchlist"
//                       }
//                     >
//                       <div className="srecat">
//                         <div className="searchlistsec ">
//                           {searchItem.length != 0 && (
//                             <h2 className="collectionslsd">Items</h2>
//                           )}

//                           {searchItem
//                             ? searchItem.map((item) => {
//                                 var url = `/info/${String(item.tokenName)}/${
//                                   item.tokenowners_current.tokenOwner
//                                 }/${item.contractAddress}/${item.tokenCounts}`;
//                                 var url_without_spaces = url.replace(" ", "");
//                                 return (
//                                   <div
//                                     className="arryasearch"
//                                     onClick={() => {
//                                       history.push(url_without_spaces);

//                                       if (
//                                         location?.pathname
//                                           .split("/")
//                                           .includes("info")
//                                       ) {
//                                         window.location.reload();
//                                       }
//                                     }}
//                                   >
//                                     <div>
//                                       {item.image != "" &&
//                                       (["webp", "gif", "jpg", "jpeg", "png"].includes(String(item?.image).split(".").pop()?.toLowerCase())) && (
//                                           <img
//                                             src={
//                                               item.additionalImage
//                                                 ? item.additionalImage == ""
//                                                   ? `${config.IPFS_IMG}/${item.ipfsimage}`
//                                                   : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`
//                                                 : `${config.IPFS_IMG}/${item.ipfsimage}`
//                                             }
//                                             alt="Collections"
//                                             className="img-fluid mr-2 user_ul_new align-self-center"
//                                           />
//                                         )}
//                                     </div>

//                                     <div className="searchlistseclist">
//                                       <h5>{item.tokenName}</h5>
//                                     </div>
//                                   </div>
//                                 );
//                               })
//                             : ""}
//                         </div>
//                         <div className="searchlistsec ">
//                           {UsersearchItem.length > 0 ? (
//                             <h2 className="collectionslsd">Users</h2>
//                           ) : (
//                             ""
//                           )}
//                           {UsersearchItem.length > 0
//                             ? UsersearchItem &&
//                               UsersearchItem.map((searchUser) => (
//                                 <div
//                                   className="arryasearch"
//                                   onClick={() =>
//                                     history.push(
//                                       searchUser.customurl != ""
//                                         ? `/${searchUser.customurl}`
//                                         : `/user/${searchUser.curraddress}`
//                                     )
//                                   }
//                                 >
//                                   <div>
//                                     {searchUser && searchUser.image != "" ? (
//                                       <img
//                                         src={`${config.Back_URL}/images/${searchUser._id}/${searchUser.image}`}
//                                       ></img>
//                                     ) : (
//                                       <img src={editprofile}></img>
//                                     )}
//                                   </div>

//                                   <div className="searchlistseclist">
//                                     <h5>
//                                       {searchUser?.name != ""
//                                         ? searchUser.name
//                                         : "noname"}
//                                     </h5>
//                                   </div>
//                                 </div>
//                               ))
//                             : ""}
//                           {isEmpty(searchItem) &&
//                             isEmpty(UsersearchItem) &&
//                             keyword && <div>No data Found </div>}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }



import React, { useState, useEffect} from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory, useLocation } from "react-router-dom";
import editprofile from "../assets/images/img-4.png";
import $ from "jquery";
import isEmpty from "../lib/isEmpty";
import config from "../lib/config";
import "react-toastify/dist/ReactToastify.css";
import { getSearchList } from "../actions/v1/user";
import "./home.css";
export default function SearchBar(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();
  var location = useLocation();
  const [show, setShow] = useState(false);
  const [searchItem, setSearchItem] = useState([]);
  const [search, setSearch] = useState(false);
  const [keyword, setKeyword] = useState();
  const [UsersearchItem, setUserSearchItem] = useState([]);

  if (show === true) {
    $("body").addClass("overflowbody");
  } else {
    $("body").removeClass("overflowbody");
  }
  useEffect(() => {
    searchinit("a");
  }, [location?.pathname]);

  const searchinit = async (keywordVal) => {
    let postData = {
      limit: 6,
      keyword: keywordVal,
    };
    var data = await getSearchList(postData);
    console.log("data",data)
    if (
      data &&
      data.searchlist &&
      data.searchlist.items &&
      data.searchlist.items.list
    ) {
      setSearchItem(data.searchlist.items.list);
    }
    if (data && data.searchlist && data.searchlist.users) {
      setUserSearchItem(data.searchlist.users);
    }
  };

  const toggleSearchmenu = async (event) => {
    var useclass = document.getElementsByClassName("searchmneu_dd");
    if (event?.target?.value?.length === 1) {
      for (var c = 0; c < useclass.length; c++) {
        useclass[c].classList.remove("d-none");
      }
    }
    if (event?.target?.value?.length === 0) {
      for (var c = 0; c < useclass.length; c++) {
        useclass[c].classList.add("d-none");
      }
    }
    let keywordVal = event.target.value;
    setKeyword(keywordVal);
    let postData = {
      limit: 6,
      keyword: keywordVal,
    };
    var data = await getSearchList(postData);
    if (
      data &&
      data.searchlist &&
      data.searchlist.items &&
      data.searchlist.items.list
    ) {
      setSearchItem(data.searchlist.items.list);
    }
    if (data && data.searchlist && data.searchlist.users) {
      setUserSearchItem(data.searchlist.users);
    }
  };

  let scrollTop = window.scrollY;
  $(window).scroll(function (event) {
    if (scrollTop > 150) {
      alert(scrollTop);
      $("nav").addClass("scroll");
    } else {
      $("nav").removeClass("scroll");
    }
  });
  var scrollpos = window.scrollY;
  var header = document.getElementById("navbar");

  function add_class_on_scroll() {
    header.classList.add("fade-in");
  }

  function remove_class_on_scroll() {
    header.classList.remove("fade-in");
  }

  window.addEventListener("scroll", function (event) {
    scrollpos = window.scrollY;

    if (scrollpos > 10) {
      add_class_on_scroll();
    } else {
      remove_class_on_scroll();
    }
  });

  window.$("body").scroll(function () {
    var scroll = window.$("body").scrollTop();

    if (scroll >= 50) {
      $("nav").addClass("scroll");
    } else {
      $("nav").removeClass("scroll");
    }
  });
  return (
    <>
      <div className="container mb-4 mb-lg-0 mt-4 mt-lg-0 search-data-token">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9 col-sm-8 col-12">
            <div className="seathbat">
              <div className="seathbats" id="seathbats">
                <div className="searchiconsd" id="searchiconsd">
                  <div className="searchbar">
                    <div className="searchbarsd">

                      <input
                        type="text"
                        placeholder="Search Data Tokens"
                        className={search ? "serchitems" : "serchitems"}
                        onChange={(e) => toggleSearchmenu(e)}
                        value={keyword}
                        onClick={() => setSearch(true)}
                        style={{
                          color:"white"
                        }}
                      />
                      <i className="fa fa-search search_galass" />
                      {search && (
                        <i
                          className="fa fa-times search_closer"
                          onClick={() => {
                            setSearch(false);
                            setKeyword("");
                          }}
                        ></i>
                      )}
                    </div>
                    <div
                      className={
                        search
                          ? "searchlist searchlist_height active"
                          : "searchlist"
                      }
                    >
                      <div className="srecat">
                        <div className="searchlistsec ">
                          {searchItem.length !== 0 && (
                            <h2 className="collectionslsd">Data Tokens</h2>
                          )}

                          {searchItem
                            ? searchItem.map((item) => {
                                var url = `/info/${String(item.tokenName)}/${
                                  item.tokenowners_current.tokenOwner
                                }/${item.contractAddress}/${item.tokenCounts}`;
                                var url_without_spaces = url.replace(" ", "");
                                return (
                                  <div
                                    className="arryasearch"
                                    onClick={() => {
                                      history.push(url_without_spaces);

                                      if (
                                        location?.pathname
                                          .split("/")
                                          .includes("info")
                                      ) {
                                        window.location.reload();
                                      }
                                    }}
                                  >
                                    <div>
                                      {item.image != "" &&
                                      (["webp", "gif", "jpg", "jpeg", "png"].includes(String(item?.image).split(".").pop()?.toLowerCase())) && (
                                          <img
                                            src={
                                              item.additionalImage
                                                ? item.additionalImage == ""
                                                  ? `${config.IPFS_IMG}/${item.ipfsimage}`
                                                  : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`
                                                : `${config.IPFS_IMG}/${item.ipfsimage}`
                                            }
                                            alt="Collections"
                                            className="img-fluid mr-2 user_ul_new align-self-center"
                                          />
                                        )}
                                    </div>

                                    <div className="searchlistseclist">
                                      <h5>{item.tokenName}</h5>
                                    </div>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                        <div className="searchlistsec ">
                          {UsersearchItem.length > 0 ? (
                            <h2 className="collectionslsd">Users</h2>
                          ) : (
                            ""
                          )}
                          {UsersearchItem.length > 0
                            ? UsersearchItem &&
                              UsersearchItem.map((searchUser) => (
                                <div
                                  className="arryasearch"
                                  onClick={() =>
                                    history.push(
                                      searchUser.customurl != ""
                                        ? `/${searchUser.customurl}`
                                        : `/user/${searchUser.curraddress}`
                                    )
                                  }
                                >
                                  <div>
                                    {searchUser && searchUser.image !== "" ? (
                                      <img
                                        src={`${config.Back_URL}/images/${searchUser._id}/${searchUser.image}`}
                                        alt=""
                                      ></img>
                                    ) : (
                                      <img src={editprofile} alt=""></img>
                                    )}
                                  </div>

                                  <div className="searchlistseclist">
                                    <h5>
                                      {searchUser?.name != ""
                                        ? searchUser.name
                                        : "noname"}
                                    </h5>
                                  </div>
                                </div>
                              ))
                            : ""}
                          {isEmpty(searchItem) &&
                            isEmpty(UsersearchItem) &&
                            keyword && <div>No Data Tokens Found </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

