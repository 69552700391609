import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header/Head.js";
import Footer from "../components/Footer/Footer.js";
import "animate.css";
import Video from "../components/video/video";
import { useSelector } from "react-redux";
import HomeSkeleton from "./HomeSkeleton.js";
import { CollectiblesList_Home, getpromotiondata } from "../actions/v1/token";
import { Link } from "react-router-dom";
import Tokencard from "../components/seperate/tokencard.js";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import isEmpty from "../lib/isEmpty";
import { Placeabid } from "../components/placeabid/placeabid";
import Loader from "./Loader.js";
import SearchBar from "../views/search_bar.js";
import bit_bucks from "../assets/images/Markeetplace/big_bucks.svg";
import growth from "../assets/images/Markeetplace/growth.svg";
import PlayBtn from "../assets/images/Markeetplace/play_btn_img.svg";
import home_star from "../assets/images/Markeetplace/light_star.svg";
import home_round from "../assets/images/Markeetplace/round.svg";
import "./home.css";
import Fade from 'react-reveal/Fade';
export default function Home() {
  var pauseVideo = document.getElementById("video_markeetplace");
  const [Time_Auction_List, Set_Time_Auction_List] = useState([]);
  const [CatName, setCatName] = useState("All");
  const [CatBasedTokenList, setCatBasedTokenList] = useState({
    loader: false,
    All: { page: 1, list: [], onmore: true },
  });
  const [TokenList, setTokenList] = useState([]);
  const [Creatorcount, setCreatorcount] = useState(0);
  const [item, Set_item] = useState({});
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [convertVal, setConvertVal] = React.useState(0);
  const [liveauc, set_liveauc] = React.useState(false);
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState("");
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState(
    "init"
  );
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState(
    "init"
  );
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState(
    {}
  );
  const [loading, Set_loading] = React.useState(true);
  const [WalletUserDetails, setWalletUserDetails] = React.useState({});
  const [BidArray, setBidArray] = useState([]);
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [isPlaying, setIsPlaying] = useState(false);

  const playvideo = () => {
    const video = document.getElementById("video_markeetplace");
    if (video) {
      setIsPlaying(true);
      video.play();
    }
  };

  const videoPause = () => {
    const video = document.getElementById("video_markeetplace");
    if (video) {
      setIsPlaying(false);
      video.pause();
    }
  };
  var PlaceABidForwardRef = useRef();

  useEffect(() => {
    getInit();
  }, [Wallet_Details.UserAccountAddr, liveauc]);

  async function getInit() {
    TokenListCall();
    timeAuctionFUnc();
  }

  const timeAuctionFUnc = async () => {
    var currAddr = Wallet_Details.UserAccountAddr;

    var payload = {
      limit: 6,
      from: "Time",
      currAddr: currAddr,
    };
    var resp = await CollectiblesList_Home(payload);
    setWalletUserDetails(resp?.data?.walletUserDetails);

    if (resp && resp.data && resp.data.list && resp.data.list.length > 0) {
      Set_Time_Auction_List(resp.data.list);

      var bids =
        resp.data.list.length > 0 &&
        resp.data.list
          .map((item, index) => {
            if (!isEmpty(item.tokenowners_current) && !isEmpty(item.higheBd)) {
              return item;
            }
          })
          .filter((ite) => ite && ite);

      setBidArray(bids);
    } else {
      Set_Time_Auction_List([]);
    }
  };

  async function TokenListCall(data = {}) {
    var currAddr = Wallet_Details.UserAccountAddr;

    var name = CatName;
    if (data.CatName) {
      name = data.CatName;
    }

    var payload = {
      limit: 9,
      page:
        CatBasedTokenList[name] && CatBasedTokenList[name].page
          ? CatBasedTokenList[name].page
          : 1,
      currAddr: currAddr,
      CatName: name,
      from: "Home",
    };

    CatBasedTokenList.loader = true;
    var resp = await CollectiblesList_Home(payload);

    setWalletUserDetails(resp?.data?.walletUserDetails);

    Set_loading(false);
    CatBasedTokenList.loader = false;
    if (
      resp &&
      resp.data &&
      resp.data.from === "token-collectibles-list-home" &&
      resp.data.list &&
      resp.data.list.length > 0
    ) {
      setTokenList(TokenList.concat(resp.data.list));
      setCreatorcount(resp.data.list.length);
      if (typeof CatBasedTokenList[name] == "undefined") {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      CatBasedTokenList[name].list = resp.data.list;

      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    } else {
      CatBasedTokenList[name].onmore = false;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
  }

  return (
    <>
      <Placeabid
        ref={PlaceABidForwardRef}
        Set_MyItemAccountAddr={Set_MyItemAccountAddr}
        Set_tokenCounts={Set_tokenCounts}
        Set_item={Set_item}
        Set_tokenCounts_Detail={Set_tokenCounts_Detail}
        Set_MyTokenBalance={Set_MyTokenBalance}
        Set_Bids={Set_Bids}
        Set_AccepBidSelect={Set_AccepBidSelect}
        Set_tokenBidAmt={Set_tokenBidAmt}
        Set_NoOfToken={Set_NoOfToken}
        Set_ValidateError={Set_ValidateError}
        Set_TokenBalance={Set_TokenBalance}
        Set_YouWillPay={Set_YouWillPay}
        Set_YouWillPayFee={Set_YouWillPayFee}
        Set_YouWillGet={Set_YouWillGet}
        Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
        MyItemAccountAddr={MyItemAccountAddr}
        tokenCounts={tokenCounts}
        item={item}
        tokenCounts_Detail={tokenCounts_Detail}
        MyTokenBalance={MyTokenBalance}
        Bids={Bids}
        AccepBidSelect={AccepBidSelect}
        tokenBidAmt={tokenBidAmt}
        NoOfToken={NoOfToken}
        ValidateError={ValidateError}
        TokenBalance={TokenBalance}
        YouWillPay={YouWillPay}
        YouWillPayFee={YouWillPayFee}
        YouWillGet={YouWillGet}
        BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
        BidApply_SignCallStatus={BidApply_SignCallStatus}
        AllowedQuantity={AllowedQuantity}
        Service_Fee={Service_Fee}
        set_Service_Fee={set_Service_Fee}
        MyItemAccountAddr_Details={MyItemAccountAddr_Details}
        setConvertVal={setConvertVal}
        convertVal={convertVal}
      />

      {/* <LikeRef ref={LikeForwardRef} setLikedTokenList={setLikedTokenList} /> */}

      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />

          {loading ? (
            <HomeSkeleton />
          ) : (
            <div className="home_header">
              {CatBasedTokenList[CatName].list.length > 0 && (
                <div className="content-banner">
                  <div
                    className="container  video_Aligned"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="video-main-container"
                      style={{ position: "relative", overflow: "hidden" }}
                    >
                      <>
                        <img
                          src={PlayBtn}
                          alt="Thumbnail"
                          className="thumbnail_container"
                          onClick={isPlaying ? videoPause : playvideo}
                          style={{ display: isPlaying ? "none" : "block" }}
                        />
                        <video
                          className="video_container"
                          width="100%"
                          height="100%"
                          autoPlay
                          controls
                          id="video_markeetplace"
                          onPlay={() => setIsPlaying(true)}
                          onPause={() => setIsPlaying(false)}
                        >
                          <source
                            src={require("../assets/images/black/watchdemo.mp4")}
                            type="video/mp4"
                          />
                        </video>
                      </>
                    </div>
                  </div>
                </div>
              )}
              <section className="container mobile_content position-relative m-auto mt-sm-4 mb-sm-4 mt-lg-0 mb-lg-0">
                <div className="home_img">
                  <img
                    className="img1 position-absolute"
                    src={home_star}
                    alt="home_star"
                  />
                  <img
                    className="img3 position-absolute"
                    src={home_round}
                    alt="home_round"
                  />
                </div>
                <div className="homebanerheight">
                  <div className="content">
                    <h1 className="robotoBold">
                      <span style={{ color: "#FFF600" }}>Discover </span>{" "}
                      <span>Grow</span>
                      <span
                        style={{
                          color: "#090806",
                          textShadow:
                            "-1px 1px 0 #FFF,1px 1px 0 #FFF,1px -1px 0 #FFF,-1px -1px 0 #FFF",
                        }}
                      >
                        {" "}
                        Resell
                      </span>{" "}
                    </h1>
                    <div className="learnmore"></div>
                  </div>
                </div>
                <SearchBar />
              </section>

              {loading ? (
                <Loader />
              ) : (
                <section className="recentlyadded position-relative">
                  <div className="container custom-container position-relative">
                    <div className="recentlyadded-img">
                      <img
                        className="img2 position-absolute"
                        src={home_star}
                        alt="home_star"
                      />
                    </div>
                    <div className="row slicing_row mb-5 container-data">
                      <div className="top-data-token">
                        <h2 className="">Top Data</h2>
                        <h1 className="">Tokens</h1>
                      </div>

                      {CatBasedTokenList &&
                      CatName &&
                      CatBasedTokenList[CatName] &&
                      CatBasedTokenList[CatName].list &&
                      CatBasedTokenList[CatName].list.length > 0 ? (
                        CatBasedTokenList[CatName].list.map((item) => {
                          return isEmpty(item.tokenowners_current) !== true ? (
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6 col-12 card_client data-card">
                              <Fade bottom>
                              <Tokencard
                                WalletUserDetails={
                                  WalletUserDetails !== undefined
                                    ? WalletUserDetails
                                    : ""
                                }
                                item={item}
                                Set_item={Set_item}
                                PlaceABid_Click={
                                  PlaceABidForwardRef?.current?.PlaceABid_Click
                                }
                                Set_Bids={Set_Bids}
                                Bids={item.myBid}
                                Set_BuyOwnerDetailFirst={
                                  Set_BuyOwnerDetailFirst
                                }
                                Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                Set_MyTokenBalance={Set_MyTokenBalance}
                                Set_MyTokenDetail={Set_MyTokenDetail}
                                Set_AllowedQuantity={Set_AllowedQuantity}
                                Set_YouWillPay={Set_YouWillPay}
                                Set_YouWillPayFee={Set_YouWillPayFee}
                                Set_YouWillGet={Set_YouWillGet}
                                setConvertVal={setConvertVal}
                                convertVal={convertVal}
                                curraddress={
                                  Wallet_Details.UserAccountAddr !== ""
                                    ? Wallet_Details.UserAccountAddr
                                    : ""
                                }
                              />
                              </Fade>
                            </div>
                          ) : (
                            ""
                          );
                        })
                      ) : (
                        <div className="text-center py-5 col-12 notfound">
                          <div className="text-center py-3  no_items_row">
                            <p className="not_found_text_sub">
                              We currently don't have any active auctions.
                              Please come back soon or search our marketplace
                              for data NFTs.
                            </p>
                            <div className="mt-3"></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-center mt-2 mb-2">
                    <Link to="/explore/All">
                      <button className="explore-button connectwallet connectwallet_mobbot">
                        {" "}
                        Explore More{" "}
                      </button>
                    </Link>
                  </div>
                </section>
              )}
              {loading ? (
                <Loader />
              ) : (
                <section className="live_auctions" id="Liveaudion">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h3>Live </h3>
                    <h1>Auctions</h1>
                  </div>
                  <div className="container custom-container">
                    <div className="row slicing_row mb-5 container-data">
                      {BidArray.length > 0 ? (
                        BidArray.map((item) => {
                          return (
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6 col-12 data-card">
                              <Fade bottom>
                              <Tokencard
                                item={item}
                                Set_item={Set_item}
                                PlaceABid_Click={
                                  PlaceABidForwardRef.current.PlaceABid_Click
                                }
                                Set_Bids={Set_Bids}
                                Bids={item.myBid}
                                Set_BuyOwnerDetailFirst={
                                  Set_BuyOwnerDetailFirst
                                }
                                Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                Set_MyTokenBalance={Set_MyTokenBalance}
                                Set_MyTokenDetail={Set_MyTokenDetail}
                                Set_AllowedQuantity={Set_AllowedQuantity}
                                Set_YouWillPay={Set_YouWillPay}
                                Set_YouWillPayFee={Set_YouWillPayFee}
                                Set_YouWillGet={Set_YouWillGet}
                                setConvertVal={setConvertVal}
                                convertVal={convertVal}
                                curraddress={
                                  Wallet_Details.UserAccountAddr != ""
                                    ? Wallet_Details.UserAccountAddr
                                    : ""
                                }
                              />
                              </Fade>
                            </div>
                          );
                        })
                      ) : (
                        <div className="text-center pb-5 col-12 notfound">
                          <div className="text-center pb-3 pt-2 no_items_row">
                            <p className="not_found_text_sub newtext_clrliveauc robotoReg footer_xpln">
                              Please come back soon for live auctions or search
                              the marketplace for data NFTs.
                            </p>
                            <div className="mt-3"></div>
                          </div>
                        </div>
                      )}
                    </div>
                    {
                      <div className="text-center mt-2">
                        <Link to="/explore/All">
                          <button className="explore-button  connectwallet">
                            Explore More{" "}
                          </button>
                        </Link>
                      </div>
                    }
                  </div>
                </section>
              )}
              {loading ? (
                <Loader />
              ) : (
                <section className="customer-section">
                  <div className="section-main">
                    <div className="customer-baner">
                      <div className="customer-div1">
                        <div>
                          <h1 className="customer-h1">
                            WHY CUSTOMER <br /> DATA IS
                            <span className="h1-span"> BIG BUCKS</span>{" "}
                          </h1>
                        </div>
                        <div className="customer-p">
                          <p>
                            Meta (which used to be Facebook) profits surged 48%
                            during the Pandemic, making $9.5 billion in the
                            first quart of 2020 alone
                          </p>
                          <p>
                            Tik Tok generated an estimated $1.9 billion during
                            2020
                          </p>
                          <p>Twitter made $3.7 billion in revenue in 2020</p>
                          <p>
                            Google reported $183 billion in revenue last year
                          </p>
                        </div>
                      </div>
                      <div className="customer-div2">
                        <img
                          src={bit_bucks}
                          width="100%"
                          height="100%"
                          alt="bit bucks"
                          style={{ maxHeight: "650px", maxWidth: "650px" }}
                        />
                      </div>
                    </div>
                    <div className="growth-baner">
                      <div className="growth-div1">
                        <img
                          src={growth}
                          width="100%"
                          height="100%"
                          alt="bit bucks"
                          style={{ maxHeight: "450px", maxWidth: "400px" }}
                        />
                      </div>
                      <div className="growth-div2">
                        <div>
                          <h1 className="growth-h1">
                            <span className="h1-span">GROWTH </span>
                            OPPERTUNITY WITH DATA
                          </h1>
                        </div>
                        <div className="growth-p">
                          <p>
                            91% are concerned about the potential abuse of their
                            data
                          </p>
                          <p>
                            46% said this concern has risen in the past five
                            years
                          </p>
                          <p>
                            70% want businesses to disclose before sharing
                            personal details with partners
                          </p>
                          <p>
                            Over half 53% say they take steps to stop companies
                            from tracking their personal data, such as opting
                            out of cookie
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          )}
          {/* 
          <div
            class="modal fade "
            id="video_modal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog  modal-lg  modal-dialog-centered modal-dialog-scrollable">
              {
                <div class="modal-content">
                  <div class="modal-body">
                    <video
                      width="100%"
                      height="100%"
                      id="home_modalvideo"
                      autoplay
                      controls
                    >
                      <source
                        src={require("../assets/images/black/watchdemo.mp4")}
                        type="video/mp4"
                      />
                    </video>

                    <center>
                      <button
                        className="bidbtn modal_cancelbtn"
                        data-bs-dismiss="modal"
                        onClick={videoPause}
                      >
                        Cancel
                      </button>
                    </center>
                  </div>
                </div>
              }
            </div>
          </div> */}
          <Footer />
        </>
      )}
    </>
  );
}
